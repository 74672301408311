<template>
  <div>
<!--    <v-btn  color="primary" class="mr-1 mt-1" outlined  @click="getNotification">
      Avisos
    </v-btn>-->

    <v-dialog
        v-model="dialogGuias"
        transition="dialog-bottom-transition"
        max-width="1200"
    >
      <v-card class="mb-2">
        <v-toolbar
            color="primary"
            dark
        >Creacion de Guias</v-toolbar>
        <v-row style="justify-content:center">
          <v-col cols="10" class="mr-2 ml-2 mt-4">
            <v-autocomplete
                :items="itemsSuppliers"
                item-text="companyName"
                item-value="id"
                label="Provedor"
                v-model="Info2.supplierId"
                dense
                @change="getBookings"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row style="justify-content:center">
          <v-col cols="2" class="mr-2 ml-2">
            <v-text-field
                label="Prefijo"
                hide-details="auto"
                v-model="Info2.folio"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="mr-2 ml-2">
            <v-text-field
                label="Numeracion de AWB de cominezo:"
                hide-details="auto"
                v-model="Info2.initialRange"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="mr-2 ml-2">
            <v-text-field
                label="Total AWB´s"
                hide-details="auto"
                v-model="Info2.quantity"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="mr-2 ml-2">
            <v-btn
                color="primary"
                class="ma-2 white--text"
                @click="newBookings"
            >
              Crear Guias
              <v-icon
                  right
                  dark
              >
                mdi-cloud-upload
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
          <v-row v-for="(range, index) in BooginsRanges" :key="index">
            <v-col cols="3" class="ml-2">
              <v-text-field readonly :label="range"></v-text-field>
            </v-col>
          </v-row>

<!--          <v-data-table
              :headers="headers4"
              :items="BooginsRanges"
              :items-per-page="5"
              class="elevation-1"
          ></v-data-table>-->
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog"
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="mr-1 mt-1"
            icon
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs"
                      v-on="on">
                mdi-account
              </v-icon>
            </template>
            <span>Clientes</span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-card>
        <v-row>
          <v-col cols="12" style="text-align: right">
            <v-btn
                color="error"
                plain
                @click="dialog = false"
            >
              <v-icon>
                mdi-minus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <ListCustomers></ListCustomers>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogS"
        fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="mr-1 mt-1"
            icon
        ><v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon dark v-bind="attrs"
                    v-on="on">
              mdi-account-tie
            </v-icon>
          </template>
          <span>Provedores</span>
        </v-tooltip>
        </v-btn>
      </template>
      <v-card>
        <v-row>
          <v-col cols="12" style="text-align: right">
            <v-btn
                color="error"
                plain
                @click="dialogS = false"
            >
              <v-icon>
                mdi-minus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <ListSuppliers></ListSuppliers>
      </v-card>
    </v-dialog>
    <v-btn
        color="primary"
        class="mr-1 mt-1"
        icon
        @click="NewGuias"
        v-if="this.shipment.transportationId === 2"

    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon dark v-bind="attrs"
                  v-on="on">
            mdi-database-plus
          </v-icon>
        </template>
        <span>stock de guias</span>
      </v-tooltip>

    </v-btn>
    <v-btn icon color="primary" @click="getlables" class="mt-1" v-if="this.shipment.transportationId === 2">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon dark v-bind="attrs"
                  v-on="on">
            mdi-label-multiple
          </v-icon>
        </template>
        <span>Etiqueta</span>
      </v-tooltip>
    </v-btn>
    <v-btn icon color="primary" @click="getNotification" class="mr-2 mt-1" v-if="this.shipment.transportationId === 2 || this.shipment.transportationId === 1 || this.shipment.transportationId === 3">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon dark v-bind="attrs"
                  v-on="on">
            mdi-bell-badge
          </v-icon>
        </template>
        <span>Avisos</span>
      </v-tooltip>
    </v-btn>

    <v-btn color="primary" icon @click="GeneratePdf" class="mt-2 mr-2">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon dark v-bind="attrs"
                  v-on="on">
            mdi-folder-download
          </v-icon>
        </template>
        <span>Descargar cotizacion</span>
      </v-tooltip>
    </v-btn>

    <v-btn color="primary" icon @click="getHouseOcean" class="mt-2 mr-2" v-if="this.shipment.transportationId === 1">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon dark v-bind="attrs"
                  v-on="on">
            mdi-file-document-edit
          </v-icon>
        </template>
        <span>Master BL</span>
      </v-tooltip>
    </v-btn>

    <v-btn color="primary" icon @click="getCost" class="mt-2 mr-5" >
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon dark v-bind="attrs"
                v-on="on">
        mdi-printer
        </v-icon>
      </template>
      <span>Rentabilidad</span>
      </v-tooltip>
    </v-btn>


    <v-btn icon color="primary" @click="getWaybill" class="mt-2 mr-5" v-if="this.shipment.transportationId === 2">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon dark v-bind="attrs"
                  v-on="on">
            mdi-file-document-edit
          </v-icon>
        </template>
        <span>Guia aerea</span>
      </v-tooltip>
    </v-btn>
    <v-dialog v-model="dialog2" max-width="800">
      <v-card width="1200">
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-file-edit</v-icon>
            Carta de instrucciones
          </h2>

          <v-spacer></v-spacer>
          <v-btn color="transparent" elevation="0">
            <v-icon class="mr-1">mdi-printer</v-icon>
            Imprimir
          </v-btn>
          <v-btn color="transparent" elevation="0">
            <v-icon class="mr-1">mdi-content-save</v-icon>
            Salvar
          </v-btn>
          <v-btn icon dark @click="dialog2 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field label="Carrier name">
          </v-text-field>
          <v-select :items="items" label="Unit type"></v-select>
          <v-text-field label="Costo">
          </v-text-field>
          <v-row>
            <v-col cols="6">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="Dia de recoleccion"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="time"
                      label="Hora de recoleccion"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="Fecha de envio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="time"
                      label="Hora de envio"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-text-field label="Commodity">
          </v-text-field>
          <v-select :items="items" label="Shipper"></v-select>
          <v-text-field label="Receiver">
          </v-text-field>
          <v-select :items="items" label="Facturar a"></v-select>
        </v-card-text>
        <v-card-actions elevation="0">
          <v-btn
              color="primary"
              @click="dialog2 = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog  v-model="MasterBLL" max-width="1500">
      <v-card max-width="1500">
        <v-toolbar app dark class="primary white--text">
          <v-row>
            <v-col cols="6">
              <h2 class="font-weight-regular">
                <v-icon size="25">mdi-file-document-edit</v-icon>
                Master BL
              </h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-select
                  :items="items3"
                  label="Tipo"
              ></v-select>
            </v-col>
            <v-btn class="mt-5" color="transparent" elevation="0">
              <v-icon class="mr-1 ">mdi-printer</v-icon>
              Imprimir guia
            </v-btn>
            <v-btn @click="putWaybill" class="mt-5" color="transparent" elevation="0">
              <v-icon class="mr-1 ">mdi-content-save</v-icon>
              Salvar
            </v-btn>
            <v-btn class="mt-3" icon dark @click="MasterBLL = false">
              <v-icon class="">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete label="Shipper's name">
              </v-autocomplete>
              <v-autocomplete label="Shipper's address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="Shipper's RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Shipper's phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>

              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Shipper's email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>

              </v-container>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="3">
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="DOCUMENT NUMBER"
                  required
              ></v-text-field>
              <v-divider horizontal class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="REFERENCE NOS:"
                  required
              ></v-text-field>
              <v-divider horizontal class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="3">
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="B/L NUMBER"
                  required
              ></v-text-field>
              <v-divider horizontal class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            </v-col>
          </v-row>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="6">
              <v-autocomplete label="Consignee´s name">
              </v-autocomplete>
              <v-autocomplete label="Consignee´s address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="Consignee´s RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Consignee´s phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Consignee´s email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
              <v-col cols="6">
              <v-autocomplete label="FORWARDING AGENT name">
              </v-autocomplete>
              <v-autocomplete label="FORWARDING AGENT address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="FORWARDING AGENT RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="FORWARDING AGENT PHONE"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Consignee´s email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              </v-col>

            </v-row>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="6">
              <v-autocomplete label="NOTIFY PARTY name">
              </v-autocomplete>
              <v-autocomplete label="Issuing Carriers's address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="NOTIFY PARTY RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="NOTIFY PARTY phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="NOTIFY PARTY email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
            </v-col>
            <v-divider vertical class="mt-3 mb-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="6">
              <v-autocomplete label="Delivery of goods name">
              </v-autocomplete>
              <v-autocomplete label="Accounting information address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="Delivery of goods RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Delivery of goods phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Delivery of goods email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
            </v-col>
          </v-row>
          <v-divider style="background-color: #3b3b3b !important;" class="mb-3"></v-divider>
          <h1>hola</h1>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <div class="ml-4 mt-6 pb-0 mb-0 d-flex justify-space-between">
            <v-autocomplete
                v-model="waybill.route.to"
                outlined dense label="To"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.by"
                outlined dense label="By First Carrier"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.to2"
                outlined dense label="To"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.by2"
                outlined dense label="By"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.to3"
                outlined dense label="To"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.by3"
                outlined dense label="By"></v-autocomplete>
            <v-select
                v-model="waybill.route.currency"
                outlined dense label="Currency"></v-select>
            <v-select
                v-model="waybill.route.chgsCode"
                outlined dense label="CHGS code"></v-select>
          </div>
          <div class="ml-4 mb-0 pb-0 mb-0 d-flex justify-space-between">
            <v-text-field
                v-model="waybill.route.valueForCarriage"
                outlined dense label="Declerd value for carrage"></v-text-field>
            <v-text-field
                v-model="waybill.route.valueForCustomer"
                outlined dense label="Declare value for Custumer"></v-text-field>
            <v-text-field
                v-model="waybill.amountOfInsurance"
                outlined dense label="Amount of Insurance"></v-text-field>
          </div>
          <v-divider style="background-color: #3b3b3b !important;" class="mb-3"></v-divider>
          <div class="ml-4 mb-2 pb-0 mb-0 d-flex justify-space-between">
            <v-autocomplete
                v-model="waybill.arrivalAirport"
                outlined dense label="AirPort of Destiantion"></v-autocomplete>
            <v-text-field
                v-model="waybill.flight"
                outlined dense label="Flight"></v-text-field>
            <v-text-field
                v-model="waybill.flightDate"
                outlined dense label="Date"></v-text-field>
            <v-text-field outlined dense label="Flight"></v-text-field>
            <v-text-field outlined dense label="Date"></v-text-field>
          </div>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="12" class="ml-3">
              <v-textarea
                  v-model="waybill.handlingInformation"
                  label="Handling Information">
              </v-textarea>
              <template>
                <v-data-table
                    :headers="headers"
                    :items="desserts"
                    hide-default-footer
                    class="mt-3"
                    grid-lines
                >
                  <template v-slot:item.pcs="props">

                    <v-text-field
                        :return-value.sync="props.item.pcs"
                        v-model="props.item.pcs"
                        :rules="[max25chars]"
                        label="Edit"
                        single-line
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.gross="props">
                    <v-text-field
                        :return-value.sync="props.item.gross"
                        v-model="props.item.gross"
                        :rules="[max25chars]"
                        label="Edit"
                        single-line
                        counter
                    ></v-text-field>

                  </template>
                  <template v-slot:item.kglb="props">
                    <v-text-field
                        :return-value.sync="props.item.kglb"
                        v-model="props.item.kglb"
                        :rules="[max25chars]"
                        label="Edit"
                        single-line
                        counter
                    ></v-text-field>

                  </template>
                  <template v-slot:item.comm="props">
                    <v-text-field
                        :return-value.sync="props.item.comm"
                        v-model="props.item.comm"
                        :rules="[max25chars]"
                        label="Edit"
                        single-line
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.chw="props">
                    <v-text-field
                        :return-value.sync="props.item.chw"
                        v-model="props.item.chw"
                        label="Edit"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.rate="props">
                    <v-text-field
                        :return-value.sync="props.item.rate"
                        v-model="props.item.rate"
                        label="Edit"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.total="props">
                    <v-text-field
                        :return-value.sync="props.item.total"
                        v-model="props.item.total"
                        label="Edit"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.Descripcion="props">
                    <v-text-field
                        :return-value.sync="props.item.Descripcion"
                        v-model="props.item.Descripcion"
                        label="Edit"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                        icon
                        color="error"
                        small
                        @click="deleteFee(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="info"
                        small
                        @click="addFee()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  s
                  <template slot="body.append">
                    <tr>
                      <th class="title">{{ totals.pcs }}</th>
                      <th class="title">{{ totals.gross }}</th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title">Total</th>

                    </tr>
                  </template>

                </v-data-table>
              </template>
            </v-col>
          </v-row>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="4">
              <h2 class="d-flex justify-center mt-6">W/Charge</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <h2 class="d-flex justify-center mt-2">Valuation Charge</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <h2 class="d-flex justify-center mt-2">Tax</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <h2 class="d-flex justify-center mt-2">Due Agent</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <h2 class="d-flex justify-center mt-2">Due Carrier</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="Total Prepaid"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="Total Collect"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="Conversion Conversion Rates"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC Charges in Dest currency"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="For Cariers use only destination"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="Charges destination"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="12">
                  <v-text-field outlined dense label="Total collect charges"></v-text-field>
                </v-col>
              </v-row>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="8">
              <template>
                <v-data-table
                    :headers="headers2"
                    :items="desserts2"
                    hide-default-footer
                    class="mt-3"
                    grid-lines
                >
                  <template v-slot:item.Descripcion="props">
                    <v-text-field
                        :return-value.sync="props.item.Descripcion"
                        v-model="props.item.Descripcion"
                        label="Descripicon"
                        single-line
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.rate="props">
                    <v-text-field
                        :return-value.sync="props.item.rate"
                        v-model="props.item.rate"
                        label="Precio"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.currency="{ item }">
                    <v-select
                        item-value="id"
                        single-line
                        :items="items2"
                        item-text="code"
                        @click="item"
                    ></v-select>
                  </template>
                  <template v-slot:item.moneda="props">
                    <v-select
                        :return-value.sync="props.item.moneda"
                        v-model="item.moneda"
                        :items="items2"
                        label="Moneda"
                    ></v-select>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                        icon
                        color="error"
                        small
                        @click="deleteFee(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="info"
                        small
                        @click="addFee()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <th class="title">{{ totals.pcs }}</th>
                      <th class="title">{{ totals.gross }}</th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title">Total</th>

                    </tr>
                  </template>

                </v-data-table>
              </template>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
              <v-autocomplete class="mr-3 ml-5" label="Shipper certifies"></v-autocomplete>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
              <v-row class="mt-6 mb-1">
                <v-col cols="12">
                  <v-textarea label="Shipper & Agent"></v-textarea>
                </v-col>
              </v-row>
              <v-row class="mt-6 mb-1">
                <v-col
                    cols="3"
                >
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="date"
                          label="Picker in menu"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols=3>
                  <v-autocomplete class="mr-3 ml-5" label="place"></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-select class="mr-3 ml-5" label="Signature"></v-select>
                </v-col>
              </v-row>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions elevation="0">
          <v-btn
              color="primary"
              @click="dialog3 = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog  v-model="dialog3" max-width="1500">
      <v-card max-width="1500">
        <v-toolbar app dark class="primary white--text">
          <v-row>
            <v-col cols="6">
              <h2 class="font-weight-regular">
                <v-icon size="25">mdi-file-document-edit</v-icon>
                Guia Aerea
              </h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-select
                  :items="items3"
                  label="Tipo"
              ></v-select>
            </v-col>
              <v-btn @click="downloadDocument2" class="mt-5" color="transparent" elevation="0">
                <v-icon class="mr-1 ">mdi-printer</v-icon>
                Imprimir guia
              </v-btn>
              <v-btn @click="putWaybill" class="mt-5" color="transparent" elevation="0">
                <v-icon class="mr-1 ">mdi-content-save</v-icon>
                Salvar
              </v-btn>
              <v-btn class="mt-3" icon dark @click="dialog3 = false">
                <v-icon class="">mdi-close</v-icon>
              </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <v-row >
            <v-col cols="2">
              <v-text-field
                  v-model="waybill.prefixAirlane"
                  :counter="10"
                  label="Prefijo Aiolinea"
                  required
              ></v-text-field>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="2">
              <v-autocomplete
                  v-model="waybill.departureAirport"
                  :counter="10"
                  label="Aeropuerto de salida"
                  required
              ></v-autocomplete>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="2">
              <v-text-field
                  v-model="waybill.airWayBill"
                  :counter="10"
                  label="Number de guia"
                  required
              ></v-text-field>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
          </v-row>
          <v-divider horizontal class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="6">
              <v-autocomplete label="Shipper's name">
              </v-autocomplete>
              <v-autocomplete label="Shipper's address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="Shipper's RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Shipper's phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>

              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Shipper's email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>

              </v-container>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="6">
              <v-autocomplete label="Airline name">
              </v-autocomplete>
              <v-autocomplete label="Airline address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="Airline RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Airline phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Airline email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>

              </v-container>
            </v-col>
          </v-row>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="6">
              <v-autocomplete label="Consignee´s name">
              </v-autocomplete>
              <v-autocomplete label="Consignee´s address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="Consignee´s RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Consignee´s phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Consignee´s email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-textarea label="Texto informativio">
                </v-textarea>
              </v-col>
            </v-row>
          </v-row>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="6">
              <v-autocomplete label="Issuing Carriers's name">
              </v-autocomplete>
              <v-autocomplete label="Issuing Carriers's address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="Issuing Carriers's RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Issuing Carriers's phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Issuing Carriers's email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
            </v-col>
            <v-divider vertical class="mt-3 mb-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="6">
              <v-autocomplete label="Accounting information name">
              </v-autocomplete>
              <v-autocomplete label="Accounting information address">
              </v-autocomplete>
              <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  :counter="10"
                  label="Accounting information RFC"
                  required
              ></v-text-field>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Accounting information phone"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
              <v-container fluid style="padding-left:0px">
                <v-combobox
                    v-model="model"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Accounting information email"
                    multiple
                    persistent-hint
                    small-chips
                >
                </v-combobox>
              </v-container>
            </v-col>
          </v-row>
          <v-divider style="background-color: #3b3b3b !important;" class="mb-3"></v-divider>
          <div class="ml-4 mb-2 pb-0 mb-0 d-flex justify-space-between">
            <v-autocomplete
                v-model="waybill.departureAirport"
                outlined dense label="AirPort of Departur"></v-autocomplete>
            <v-text-field
                v-model="waybill.departureAirportInitials"
                outlined dense label="Inicials"></v-text-field>
              <v-text-field
                  :counter="10"
                  label="Agent´s IATA Code"
                  required
                  outlined
                  dense
              ></v-text-field>
              <v-text-field
                  :counter="10"
                  label="Account No."
                  required
                  outlined
                  dense
              ></v-text-field>
          </div>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <div class="ml-4 mt-6 pb-0 mb-0 d-flex justify-space-between">
            <v-autocomplete
                v-model="waybill.route.to"
                outlined dense label="To"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.by"
                outlined dense label="By First Carrier"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.to2"
                outlined dense label="To"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.by2"
                outlined dense label="By"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.to3"
                outlined dense label="To"></v-autocomplete>
            <v-autocomplete
                v-model="waybill.route.by3"
                outlined dense label="By"></v-autocomplete>
            <v-select
                v-model="waybill.route.currency"
                outlined dense label="Currency"></v-select>
            <v-select
                v-model="waybill.route.chgsCode"
                outlined dense label="CHGS code"></v-select>
          </div>
          <div class="ml-4 mb-0 pb-0 mb-0 d-flex justify-space-between">
            <v-text-field
                v-model="waybill.route.valueForCarriage"
                outlined dense label="Declerd value for carrage"></v-text-field>
            <v-text-field
                v-model="waybill.route.valueForCustomer"
                outlined dense label="Declare value for Custumer"></v-text-field>
            <v-text-field
                v-model="waybill.amountOfInsurance"
                outlined dense label="Amount of Insurance"></v-text-field>
          </div>
          <v-divider style="background-color: #3b3b3b !important;" class="mb-3"></v-divider>
          <div class="ml-4 mb-2 pb-0 mb-0 d-flex justify-space-between">
            <v-autocomplete
                v-model="waybill.arrivalAirport"
                outlined dense label="AirPort of Destiantion"></v-autocomplete>
            <v-text-field
                v-model="waybill.flight"
                outlined dense label="Flight"></v-text-field>
            <v-text-field
                v-model="waybill.flightDate"
                outlined dense label="Date"></v-text-field>
            <v-text-field outlined dense label="Flight"></v-text-field>
            <v-text-field outlined dense label="Date"></v-text-field>
          </div>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="12" class="ml-3">
              <v-textarea
                  v-model="waybill.handlingInformation"
                  label="Handling Information">
              </v-textarea>
              <template>
                <v-data-table
                    :headers="headers"
                    :items="desserts"
                    hide-default-footer
                    class="mt-3"
                    grid-lines
                >
                  <template v-slot:item.pcs="props">

                    <v-text-field
                        :return-value.sync="props.item.pcs"
                        v-model="props.item.pcs"
                        :rules="[max25chars]"
                        label="Edit"
                        single-line
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.gross="props">
                    <v-text-field
                        :return-value.sync="props.item.gross"
                        v-model="props.item.gross"
                        :rules="[max25chars]"
                        label="Edit"
                        single-line
                        counter
                    ></v-text-field>

                  </template>
                  <template v-slot:item.kglb="props">
                    <v-text-field
                        :return-value.sync="props.item.kglb"
                        v-model="props.item.kglb"
                        :rules="[max25chars]"
                        label="Edit"
                        single-line
                        counter
                    ></v-text-field>

                  </template>
                  <template v-slot:item.comm="props">
                    <v-text-field
                        :return-value.sync="props.item.comm"
                        v-model="props.item.comm"
                        :rules="[max25chars]"
                        label="Edit"
                        single-line
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.chw="props">
                    <v-text-field
                        :return-value.sync="props.item.chw"
                        v-model="props.item.chw"
                        label="Edit"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.rate="props">
                    <v-text-field
                        :return-value.sync="props.item.rate"
                        v-model="props.item.rate"
                        label="Edit"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.total="props">
                    <v-text-field
                        :return-value.sync="props.item.total"
                        v-model="props.item.total"
                        label="Edit"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.Descripcion="props">
                    <v-text-field
                        :return-value.sync="props.item.Descripcion"
                        v-model="props.item.Descripcion"
                        label="Edit"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                        icon
                        color="error"
                        small
                        @click="deleteFee(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="info"
                        small
                        @click="addFee()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  s
                  <template slot="body.append">
                    <tr>
                      <th class="title">{{ totals.pcs }}</th>
                      <th class="title">{{ totals.gross }}</th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title">Total</th>

                    </tr>
                  </template>

                </v-data-table>
              </template>
            </v-col>
          </v-row>
          <v-divider style="background-color: #3b3b3b !important;"></v-divider>
          <v-row>
            <v-col cols="4">
              <h2 class="d-flex justify-center mt-6">W/Charge</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <h2 class="d-flex justify-center mt-2">Valuation Charge</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <h2 class="d-flex justify-center mt-2">Tax</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <h2 class="d-flex justify-center mt-2">Due Agent</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <h2 class="d-flex justify-center mt-2">Due Carrier</h2>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="PP"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC"></v-text-field>
                </v-col>
              </v-row>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="Total Prepaid"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="Total Collect"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="Conversion Conversion Rates"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="CC Charges in Dest currency"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="6">
                  <v-text-field outlined dense label="For Cariers use only destination"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field outlined dense label="Charges destination"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-around ml-4 mr-5 mt-0">
                <v-col cols="12">
                  <v-text-field outlined dense label="Total collect charges"></v-text-field>
                </v-col>
              </v-row>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
            </v-col>
            <v-divider vertical class="mt-3" style="background-color: #3b3b3b !important;"></v-divider>
            <v-col cols="8">
              <template>
                <v-data-table
                    :headers="headers2"
                    :items="desserts2"
                    hide-default-footer
                    class="mt-3"
                    grid-lines
                >
                  <template v-slot:item.Descripcion="props">
                    <v-text-field
                        :return-value.sync="props.item.Descripcion"
                        v-model="props.item.Descripcion"
                        label="Descripicon"
                        single-line
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.rate="props">
                    <v-text-field
                        :return-value.sync="props.item.rate"
                        v-model="props.item.rate"
                        label="Precio"
                        single-line
                        :rules="[max25chars]"
                        counter
                    ></v-text-field>
                  </template>
                  <template v-slot:item.currency="{ item }">
                    <v-select
                        item-value="id"
                        single-line
                        :items="items2"
                        item-text="code"
                        @click="item"
                    ></v-select>
                  </template>
                  <template v-slot:item.moneda="props">
                    <v-select
                        :return-value.sync="props.item.moneda"
                        v-model="item.moneda"
                        :items="items2"
                        label="Moneda"
                    ></v-select>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                        icon
                        color="error"
                        small
                        @click="deleteFee(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="info"
                        small
                        @click="addFee()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <th class="title">{{ totals.pcs }}</th>
                      <th class="title">{{ totals.gross }}</th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title"></th>
                      <th class="title">Total</th>

                    </tr>
                  </template>

                </v-data-table>
              </template>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
              <v-autocomplete class="mr-3 ml-5" label="Shipper certifies"></v-autocomplete>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
              <v-row class="mt-6 mb-1">
                <v-col cols="12">
                  <v-textarea label="Shipper & Agent"></v-textarea>
                </v-col>
              </v-row>
              <v-row class="mt-6 mb-1">
                <v-col
                    cols="3"
                >
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="date"
                          label="Picker in menu"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols=3>
                  <v-autocomplete class="mr-3 ml-5" label="place"></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-select class="mr-3 ml-5" label="Signature"></v-select>
                </v-col>
              </v-row>
              <v-divider style="background-color: #3b3b3b !important;"></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions elevation="0">
          <v-btn
              color="primary"
              @click="dialog3 = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="LabalData" v-model="dialog4" max-width="600">
      <v-card width="600">
        <v-toolbar app dark class="primary white--text">
          <h2 class="font-weight-regular">
            <v-icon size="25">mdi-label-multiple</v-icon>
            Etiqueta
          </h2>
          <v-spacer></v-spacer>
          <v-btn
              @click="downloadDocument"
              color="transparent" elevation="0">
            <v-icon class="mr-1">mdi-printer</v-icon>
            Imprimir
          </v-btn>
          <v-btn @click="lablePut" color="transparent" elevation="0">
            <v-icon class="mr-1">mdi-content-save</v-icon>
            Guardar
          </v-btn>
          <v-btn icon dark @click="dialog4 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="5">
              <v-text-field
                  v-model="LabalData.destination"
                  label="Destination (Final)"></v-text-field>
            </v-col>
            <v-divider vertical class="mt-3 mb-3" style="background-color: black !important;"></v-divider>
            <v-col cols="3">
              <v-text-field
                  v-model="LabalData.airline"
                  label="Airline"></v-text-field>
            </v-col>
            <v-divider vertical class="mt-3 mb-3" style="background-color: black !important;"></v-divider>
            <v-col cols="4">
              <v-text-field
                  v-model="LabalData.departureAirport"
                  class="mt-4 ml-2 mr-3" label="Airpot of Departure"></v-text-field>
            </v-col>
          </v-row>
          <v-divider style="background-color: black !important;"></v-divider>
          <v-row class="ml-1 mt-1 mb-2 mr-1">
            <v-text-field
                v-model="LabalData.masterNo"
                label="Master - AWB No."></v-text-field>
          </v-row>
          <v-divider style="background-color: black !important;"></v-divider>
        <v-row class="mt-0 ml-1">
          <v-col cols="4">
            <v-text-field
                v-model="LabalData.totalPackages"
                class="ml-0 mr-3" label="Total Packages"></v-text-field>
          </v-col>
          <v-divider vertical class="mb-0" style="background-color: black !important;"></v-divider>
          <v-col cols="8">
            <v-text-field
                v-model="LabalData.totalWeight"
                class="ml-3 mr-3" label="Total Weight"></v-text-field>
          </v-col>
        </v-row>
          <v-divider class="mt-3" style="background-color: black !important;"></v-divider>
          <v-row class="mt-0 ml-1">
            <v-col cols="4">
              <v-text-field
                  v-model="LabalData.hawbNoPieces"
                  class="ml-0 mr-3" label="Hawb No. of pieces"></v-text-field>
            </v-col>
            <v-divider vertical class="mb-0" style="background-color: black !important;"></v-divider>
            <v-col cols="8">
              <v-text-field
                  v-model="LabalData.hawbWeight"
                  class="ml-3 mr-3" label="Hawb Weight"></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mt-3" style="background-color: black !important;"></v-divider>
          <v-row class="ml-1 mt-1 mb-2 mr-1">
            <v-text-field
                v-model="LabalData.houseNo"
                label="House - AWB No."></v-text-field>
          </v-row>
          <v-divider class="mt-3" style="background-color: black !important;"></v-divider>
        </v-card-text>
        <v-card-actions elevation="0">
          <v-btn
              color="primary"
              @click="lablePut"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog6" max-width="1200">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Avisos
        </v-card-title>
        <v-row>
          <v-col v-if="shipment.transportationId === 1" class="ml-4">
            <v-row>
              <v-col cols="12" class="mt-4 mb-3">
                <h3>Aviso Maritimo</h3>
              </v-col>
            </v-row>
            <p>Cliente.................: {{obj.Client ? obj.Client: 'NA'}} </p>
            <p>Tipo de Servicio .... {{obj.Movement ? obj.Movement: 'NA'}}</p>
            <p>Folio .... {{obj.ClientOrder ? obj.ClientOrder: 'NA'}}</p>
            <p>Orden Cliente .... {{obj.RefNumber ? obj.RefNumber: 'NA'}}</p>
            <p>Naviera.................: {{obj.Carrier ? obj.Carrier: 'NA'}} </p>
            <p>Buque.................: {{obj.ShipName ? obj.ShipName: 'NA'}} </p>
            <p>Viaje.................: {{obj.FlightNumber ? obj.FlightNumber: 'NA'}} </p>
            <p>POL.................: {{obj.Pol ? obj.Pol: 'NA'}} </p>
            <p>POD.................: {{obj.Pod ? obj.Pod: 'NA'}} </p>
            <p>ETD .... {{obj.DepartureDate ? obj.DepartureDate: 'NA'}}</p>
            <p>ETA .... {{obj.ArrivalDate ? obj.ArrivalDate: 'NA'}}</p>
            <p>Origen .... {{obj.Origin ? obj.Origin: 'NA'}}</p>
            <p>Destino .... {{obj.Destination ? obj.Destination: 'NA'}}</p>
            <p>B/L .... {{obj.Mbl ? obj.Mbl: 'NA'}}</p>
            <p>H B/L .... {{obj.Hbl ? obj.Hbl: 'NA'}}</p>
            <p>Consignatario .... {{obj.Client ? obj.Client: 'NA'}}</p>
            <p>Incoterm .... {{obj.Incoterm ? obj.Incoterm: 'NA'}}</p>
            <p>No. Contenedor .... {{obj.ContainerNumbers ? obj.ContainerNumbers: 'NA'}}</p>
            <p>Precintos/Sellos .... {{obj.ContainerSeals ? obj.ContainerSeals: 'NA'}}</p>
            <p>Commodity .... {{obj.Commodity ? obj.Commodity: 'NA'}}</p>
            <p>Bultos .... {{obj.Bultos ? obj.Bultos: 'NA'}}</p>
            <p>Peso .... {{obj.TotalWeight ? obj.TotalWeight: 'NA'}}</p>
            <p>CBM .... {{obj.Cbm ? obj.Cbm: 'NA'}}</p>
            <p>----------------------------------------------------</p>
            <v-row>
              <v-col cols="12" class="mb-3">
                <h4>Detalle de Gastos</h4>
                <p>-Total USD: {{obj.TotalUsd}}</p>
                <p>-Total Mxn: {{obj.TotalMxn}}</p>
                <p>-Total Eur: {{obj.TotalEur}}</p>
              </v-col>
            </v-row>
            <div v-for="(obj) in obj.Fees" :key="obj">
              <p>- Cargo: {{obj.Cargo ? obj.Cargo: 'NA'}}      ${{obj.Price ? obj.Price: 'NA'}} {{obj.Currency ? obj.Currency : 'NA'}}</p>
            </div>
          </v-col>
        </v-row>
        <v-col v-if="shipment.transportationId === 2">
            <v-row>
              <v-col cols="12" class="mt-4">
                <h3>Aviso Aereo</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ml-2">
                <p>Cliente.................: {{obj.Client ? obj.Client: 'NA'}} </p>
                <p>Tipo de Servicio .... {{obj.Movement ? obj.Movement: 'NA'}}</p>
                <p>Folio .... {{obj.ClientOrder ? obj.ClientOrder: 'NA'}}</p>
                <p>Orden de cliente .... {{obj.RefNumber ? obj.RefNumber: 'NA'}}</p>
                <p>Aerolínea.................: {{obj.Carrier ? obj.Carrier: 'NA'}} </p>
                <p>Vuelo.................: {{obj.FlightNumber ? obj.FlightNumber: 'NA'}} </p>
                <p>AOL.................: {{obj.Pol ? obj.Pol: 'NA'}} </p>
                <p>AOD.................: {{obj.Pod ? obj.Pod: 'NA'}} </p>
                <p>ETD .... {{obj.DepartureDate ? obj.DepartureDate: 'NA'}}</p>
                <p>ETA .... {{obj.ArrivalDate ? obj.ArrivalDate: 'NA'}}</p>
                <p>Origen .... {{obj.Origin ? obj.Origin: 'NA'}}</p>
                <p>Destino .... {{obj.Destination ? obj.Destination: 'NA'}}</p>
                <p>M/AWB .... {{obj.Mbl ? obj.Mbl: 'NA'}}</p>
                <p>H/AWB .... {{obj.Hbl ? obj.Hbl: 'NA'}}</p>
                <p>Consignatario .... {{obj.Client ? obj.Client: 'NA'}}</p>
                <p>Incoterm .... {{obj.Incoterm ? obj.Incoterm: 'NA'}}</p>
                <p>Contenedores .... {{obj.Containers ? obj.Containers: 'NA'}}</p>
                <p>No. Contenedor .... {{obj.ContainerNumbers ? obj.ContainerNumbers: 'NA'}}</p>
                <p>Precintos/Sellos .... {{obj.ContainerSeals ? obj.ContainerSeals: 'NA'}}</p>
                <p>Commodity .... {{obj.Commodity ? obj.Commodity: 'NA'}}</p>
                <p>Bultos .... {{obj.Bultos ? obj.Bultos: 'NA'}}</p>
                <p>Peso .... {{obj.TotalWeight ? obj.TotalWeight: 'NA'}}</p>
                <p>CBM .... {{obj.Cbm ? obj.Cbm: 'NA'}}</p>
                <p>----------------------------------------------------</p>
                <v-col cols="12" class="mb-3">
                  <h4>Detalle de Gastos</h4>
                  <p>-Total USD: {{obj.TotalUsd}}</p>
                  <p>-Total Mxn: {{obj.TotalMxn}}</p>
                  <p>-Total Eur: {{obj.TotalEur}}</p>
                </v-col>
                <div v-for="(obj) in obj.Fees" :key="obj">
                  <p>Cargo: {{obj.Cargo ? obj.Cargo: 'NA'}}      ${{obj.Price ? obj.Price: 'NA'}} {{obj.Currency ? obj.Currency : 'NA'}}</p>
                </div>
              </v-col>

            </v-row>
          </v-col>

        <v-col v-if="shipment.transportationId === 3">
          <v-row>
            <v-col cols="12" class="mt-4">
              <h3>Aviso Terrestre</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-2">
              <p>Cliente.................: {{obj.Client ? obj.Client: 'NA'}} </p>
              <p>Tipo de Servicio .... {{obj.Movement ? obj.Movement: 'NA'}}</p>
              <p>Folio .... {{obj.ClientOrder ? obj.ClientOrder: 'NA'}}</p>
              <p>Orden de cliente .... {{obj.RefNumber ? obj.RefNumber: 'NA'}}</p>
              <p>Linea Terrestre.................: {{obj.Carrier ? obj.Carrier: 'NA'}} </p>
              <p>Origen .... {{obj.Origin ? obj.Origin: 'NA'}}</p>
              <p>Destino .... {{obj.Destination ? obj.Destination: 'NA'}}</p>
              <p>No. Placas .... {{obj.ContainerSeals ? obj.ContainerSeals: 'NA'}}</p>
              <p>Consignatario .... {{obj.Client ? obj.Client: 'NA'}}</p>
              <p>Incoterm .... {{obj.Incoterm ? obj.Incoterm: 'NA'}}</p>
              <p>Contenedores .... {{obj.Containers ? obj.Containers: 'NA'}}</p>
              <p>No. Contenedor .... {{obj.ContainerNumbers ? obj.ContainerNumbers: 'NA'}}</p>
              <p>Commodity .... {{obj.Commodity ? obj.Commodity: 'NA'}}</p>
              <p>Bultos .... {{obj.Bultos ? obj.Bultos: 'NA'}}</p>
              <p>Peso .... {{obj.TotalWeight ? obj.TotalWeight: 'NA'}}</p>
              <p>CBM .... {{obj.Cbm ? obj.Cbm: 'NA'}}</p>
              <p>----------------------------------------------------</p>
              <v-col cols="12" class="mb-3">
                <h4>Detalle de Gastos</h4>
                <p>-Total USD: {{obj.TotalUsd}}</p>
                <p>-Total Mxn: {{obj.TotalMxn}}</p>
                <p>-Total Eur: {{obj.TotalEur}}</p>
              </v-col>
              <div v-for="(obj) in obj.Fees" :key="obj">
                <p>Cargo: {{obj.Cargo ? obj.Cargo: 'NA'}}      ${{obj.Price ? obj.Price: 'NA'}}   {{obj.Currency ? obj.Currency : 'NA'}}</p>
              </div>
            </v-col>

          </v-row>
        </v-col>

      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import ListCustomers from "../../../../components/Users/List-Customers";
import ListSuppliers from "../../../../components/Users/List-Suppliers";
//import Quotation from "@/prototypes/quotation";
var fileDownload = require('js-file-download')
export default {
  components: { ListCustomers, ListSuppliers},
  props: {
    shipment: {
      type: Object,
    },
  },
  data() {
    return {

      Info2: {
        id: 0,
        supplierId: null,
        folio: "",
        initialRange: null,
        finalRange:0,
        interval: 11,
        quantity: null,
      },
      headers4: [
        {
          text: 'Guias Disponibles',
          align: 'start',
          sortable: false,
          value: 'data',
        },
      ],
      GuideProvide:null,
      folio:null,
      initialRange:null,
      finalRange:null,
      model: null,
      itemsSuppliers: [],
      BooginsRanges: [],
      date: null,
      menu: null,
      tab2: false,
      dialogGuias: false,
      dialog: false,
      dialogS:false,
      obj: {},
      LabalData: null,
      waybill: {
        prefixAirlane: null,
        departureAirport: null,
        departureAirportInitials: null,
        airWayBill: null,
        arrivalAirport: null,
        route: {
          to: null,
          by: null,
          to2: null,
          by2: null,
          to3: null,
          by3: null,
          currency: null,
          chgsCode:null,
          vt: null, //nose
          val:null, // nose
          other1: null, // nose
          other: null, // nose
          valueForCarriage: null,
          valueForCustomer: null,
        },
        flight: null,
        flightDate: new Date().toISOString().substr(0, 10),
        amountOfInsurance: 0.00,
        handlingInformation: null,
        cargo:{
          noPieces: 0,
          grossWeight: 0.00,
          ratableWeight: 0.00,
          commodity: null,
          totalCostRatableWeight: 0.00,
          rate: 0,
          total: 0.00,
          goodsDescription: null,
        },
        additionalCharges:{
          description: null,
          price: 0.00,
          currency: null,
        }
      },
      items3: ['Master', 'House'],
      items2: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      radioGroup: 1,
      radioGroup1: false,
      checkbox: true,
      selected: [2],
      items: [
        {
          action: '15 min',
          headline: 'Brunch this weekend?',
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          title: 'Ali Connors',
        },
        {
          action: '2 hr',
          headline: 'Summer BBQ',
          subtitle: `Wish I could come, but I'm out of town this weekend.`,
          title: 'me, Scrott, Jennifer',
        },
        {
          action: '6 hr',
          headline: 'Oui oui',
          subtitle: 'Do you have Paris recommendations? Have you ever been?',
          title: 'Sandra Adams',
        },
        {
          action: '12 hr',
          headline: 'Birthday gift',
          subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
          title: 'Trevor Hansen',
        },
        {
          action: '18hr',
          headline: 'Recipe to try',
          subtitle: 'We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
          title: 'Britta Holt',
        },
      ],
      search: '',
      headers10: [
        {
          text: 'Dessert (100g serving)',
          align: 'start',
          filterable: false,
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' },
      ],
      desserts10: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%',
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%',
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: '8%',
        },
        {
          name: 'Gingerbread',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: '16%',
        },
        {
          name: 'Jelly bean',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: '0%',
        },
        {
          name: 'Lollipop',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: '2%',
        },
        {
          name: 'Honeycomb',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: '45%',
        },
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: '22%',
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: '6%',
        },
      ],
      tabs:null,
      tab:0,
      grossTotal: 0,
      MasterBLL: false,
      dialog0: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialog6: false,
      headers: [
        {
          text: 'PCS',
          sortable: false,
          value: 'pcs',
        },
        {text: 'Gross W.', value: 'gross', sortable: false,},
        {text: 'KG/LB', value: 'kglb', sortable: false,},
        {text: 'Commodity', value: 'comm', sortable: false,},
        {text: 'Chargable weight', value: 'chw', sortable: false,},
        {text: 'Rate', value: 'rate', sortable: false},
        {text: 'Total', value: 'total', sortable: false},
        {text: 'Descripcion', value: 'Descripcion', sortable: false},
        {
          text: "",
          value: "action",
        },
      ],
      headers2: [
        {text: 'Descripcion', value: 'Descripcion', sortable: false},
        {text: 'Precio', value: 'rate', sortable: false},
        {text: 'Moneda', value: 'currency', sortable: false},
        {
          text: "",
          value: "action",
        },
      ],
      desserts: [
        {
          pcs: '',
          gross: '',
          kglb: '',
          comm: '',
          chw: '',
          rate: '',
          Descripcion: '',
        },
      ],
      desserts2: [
        {
          currency: '',
          rate: '',
          Descripcion: '',
        },
      ],
    }
  },
  methods: {

    GeneratePdf: function () {
      this.$store.dispatch('working', true);



        this.$http({
          url: `Shipping/${this.shipment.id}/quotation`,
          method: 'get',
          responseType: 'blob', // important
        }).then(response => {
          fileDownload(response.data, `${this.shipment.refCode}.pdf`);
          this.$toast.info('Pdf Generado');

        }).catch(() => {
          this.$toast.error("Algo salio mal, intente mas tarde")

        }).finally(() => {
          this.$store.dispatch('working', false);
        })
    },





    getBookings() {
      let index = this.itemsSuppliers.findIndex((x) => x.id === this.Info2.supplierId)
      console.log('elIndex', index)
      this.Info2.folio = this.itemsSuppliers[index].prefix
      this.$http.get(`Shipping/booking/ranges/available/?supplierId=${this.Info2.supplierId}`).then((response) => {
        this.BooginsRanges = response.data.data;
      });
    },
    getCost(){
      this.$store.dispatch("working", true);
      console.log(this.paymentId)
      this.$http.post(`Shipping/${this.shipment.id}/generate/file`)
          .then((response) => {
            console.log(response)
            this.getProfability();
          }).catch(() => {
            this.$toast.error("Ocurrio un error al calcular la rentabilidad")
          }).finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    getProfability(){
      this.$http
          .get(`https://sadmin.senator-int.com/api/shipment/file?ref_code=${this.shipment.refCode}`)
          .then((response) => {
            let iframe = document.createElement("iframe");
            iframe.srcdoc = response.data;
            iframe.style.display = "none";
            iframe.onload = function () {
              setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
              }, 1);
            };
            document.body.appendChild(iframe); //load content in an iframe to print later
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    newBookings() {
      console.log(this.Info2);
      this.$store.dispatch("working", true);
      this.$http
          .post(
              `Suppliers/${this.Info2.supplierId}/booking/ranges`,
              this.Info2
          )
          .then((response) => {
            console.log(response);
            this.$toast.info("Se an creado nuevas guias");
            this.Info2.supplierId = null;
            this.Info2.folio = "";
            this.Info2.initialRange = null;
            this.Info2.finalRange = null;
            this.Info2.interval = 11;

          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
      // console.log(this.prices.details[0].shippingPurchaseId)
    },




    getSuppliers() {
      this.$http
          .get("Suppliers")
          .then((response) => {
            this.itemsSuppliers = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    NewGuias(){
      this.dialogGuias=true
      this.getSuppliers();
    },


    getNotification(){
      this.dialog6=true
      console.log(this.shipment)
      console.log()
      this.$store.dispatch("working", true);
      this.$http(`Shipping/${this.shipment.id}/notification/generate`)
          .then((response) => {
            this.NotificationData = response.data.data;
            const obj = JSON.parse(this.NotificationData,);
            console.log(obj)
            console.log(obj.Client)
            this.obj = obj
            console.log(this.NotificationData)
          }).catch((error) => {
        console.log(error);
      }).finally(()=>{
        this.$store.dispatch("working", false);
      })

    },


    getHouseOcean(){
      console.log(this.shipment)
      console.log()
      this.dialog3=true
      /*this.$http(`Shipping/${this.shipment.id}/house/${this.shipment.masterBl.houseBls[0].id}/waybill`)
          .then((response) => {
            this.HouseData = response.data.data;
            console.log(this.HouseData)
          }).catch((error) => {
        console.log(error);
      }).finally(()=>{})*/

    },


    deleteFee(item) {
      // let index = this.prices.findIndex(x=> x.id === this.shipmentId)
      // el codigo de arriba fue donde mireles me enseño -Quintero
      // realmente lo de abajo no se necesita porque ya tengo el id del fee pero era para practicar xd
      console.log(item);
      let index = this.sales.details[0].fees.findIndex((x) => x.id === item.id);
      if (index === -1) {
        this.$toast.error("No se pudo eliminar :(");
      } else {
        this.$http
            .delete(
                `shipping/${this.shipment.id}/sale/details/${this.sales.details[0].id}/fee/${item.id}`
            )
            .then((response) => {
              console.log(response);
            });
      }
    },
    getlables(){
        this.dialog4=true
        this.$store.dispatch("working", true);
        this.$http(`Shipping/${this.shipment.id}/label`,).then((response) => {
          this.LabalData = response.data.data;
          console.log(this.LabalData)
        }).catch((error) => {
          console.log(error);
          this.$toast.error("Algo salio mal, contacte al administrador!");
        }).finally(()=>{
          this.$store.dispatch("working", false);
        })

    },

    lablePut() {
      this.$http.put(`Shipping/${this.shipment.id}/label`, this.LabalData)
          .then((response) => {
            console.log(response);
            this.dialog4 = false;
            this.$toast.info("Guardado con exito");
            //fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          });

    },
    getWaybill(){
      this.dialog3=true
      this.$store.dispatch("working", true);
      this.$http(`Shipping/${this.shipment.id}/master/${this.shipment.masterBl.id}/waybill`, this.waybill)
          .then((response) => {
        this.LabalData = response.data.data;
        console.log(this.LabalData)
      }).catch((error) => {
        console.log(error);
      }).finally(()=>{
        this.$store.dispatch("working", false);
      })

    },

    putWaybill() {
      this.$http.put(`Shipping/${this.shipment.id}/master/${this.shipment.masterBl.id}/waybill`, this.waybill)
          .then((response) => {
            console.log(response);
            this.dialog4 = false;
            this.$toast.info("Guardado con exito");
            //fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          });

    },

    downloadDocument(doc) {
      console.log(doc)
      console.log(this.prices)
      this.$store.dispatch("working", true);
      this.$http({
        url: `Shipping/${this.shipment.id}/label/download`,
        method: "GET",
        responseType: "blob", // important
      })
          .then((response) => {
            console.log(response);
            fileDownload(response.data);
            this.$toast.info('Pdf Generado');
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          })
          .catch(() => {
            this.$toast.error("Algo salio mal, intente mas tarde");
            // console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },


    downloadDocument2(doc) {
      console.log(doc, "soy jost")
      console.log(this.prices)
      this.$store.dispatch("working", true);
      this.$http({
        url: `Shipping/${this.shipment.id}/master/${this.shipment.masterBl.id}/waybill/download`,
        method: "GET",
        responseType: "blob", // important
      })
          .then((response) => {
            console.log(response);
            fileDownload(response.data);
            this.$toast.info('Pdf Generado');
            var file = new Blob([response.data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          })
          .catch(() => {
            this.$toast.error("Algo salio mal, intente mas tarde");
            // console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },





    addFee() {
      let defaultFee = {
        pcs: 1,
        gross: 1,
        kglb: 0,
        comm: 0,
        chw: false,
        rate: false,
        Descripcion: "Default Cargo",
      };
      this.sales.details[0].fees.push(defaultFee);
    },
    save() {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancel() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close() {
      console.log('Dialog closed')
    },
  },

  computed: {
    totals() {
      const totals = this.desserts.reduce((acc, d) => {
        acc.pcs += +d.pcs
        acc.gross += +d.gross
        return acc
      }, {
        pcs: 0,
        gross: 0,
      })
      return totals
    }
  },
  mounted() {
  }

}

</script>
